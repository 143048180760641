<template>
    <h1>Create Event</h1>
</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>